import React from 'react';
import {CircularProgress  } from '@mui/material';
import { useState, useEffect } from 'react';
import { getAnnualSummary } from '../services/api';
import AnnualDashboard from '../components/AnnualSummary';
import { FilterZeroes, CompareRatio } from '../components/EMCUtils';
import { RenderDashboardHeader,WaitForData } from '../components/EMCRenderUtils';

const AnnualDashboardPage=() =>{
  const [loading,setLoading] = useState(true);
  const [summary,setSummary] = useState([]);
  useEffect( async () => {
    getAnnualSummary()
//        .then(t=> FilterZeroes(t))
        .then(t=>t.sort(CompareRatio))
        .then (t=> { setSummary(t); setLoading(false)});
  },[]);

  
    return (
      <>
      <RenderDashboardHeader maintext="Annual Dashboard" subtext="2025"/>
      <AnnualDashboard Summary={summary}/>
      <WaitForData show={loading}/>
      </>
    );
}

export default AnnualDashboardPage;
